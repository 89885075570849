import React from "react";
import { SwmcFooter } from "footer-component";

import "./footerNew.module.css";

const CustomFooter = () => {
  return (
    <div>
      <SwmcFooter type="swmc" showTacanewLogo={true} nydisclaimer={true} />
    </div>
  );
};

export default CustomFooter;